import type { FC } from 'react';
import { useMemo } from 'react';
import Image from 'next/image';
import { setCookie } from 'nookies';
import { usePathname } from 'next/navigation';
import { cn } from 'class-merge';
import { Select } from '~components/select';
import appConfig from '~build-config/config.json';
import { RegionEnum } from '~constants/etc';

export const languageToRegionMap = {
  en: RegionEnum.US,
  th: RegionEnum.TH,
  vi: RegionEnum.VN,
};

const SelectLanguageWidget: FC<{
  locale: string;
  className?: string;
  optionClassName?: string;
}> = ({ locale, className, optionClassName }) => {
  const pathname = usePathname();

  const regionCodeOptions = useMemo(() => {
    return appConfig.i18n.supportedLngs.map((lang) => {
      const region = languageToRegionMap[lang];
      return {
        label: (
          <div
            className="text-color flex items-center gap-2 text-xs font-semibold"
            key={lang}
          >
            <Image
              alt={`${region}`}
              height={10}
              src={`https://flagcdn.com/h60/${region}.png`}
              width={20}
            />
            {region.toUpperCase()}
          </div>
        ),
        value: lang,
      };
    });
  }, [appConfig]);

  const handleSelectLanguage = ({ value }): void => {
    const path = pathname.slice(3);
    setCookie(undefined, 'NEXT_LOCALE', value, { path: '/' });
    window.location.href = `/${value}${path}`;
  };

  return (
    <div className={cn(className)}>
      <Select
        className="text-select-primary h-[40px] w-full rounded-lg border-none px-4 outline-none"
        defaultSelectedOption={locale}
        onSelect={handleSelectLanguage}
        optionClassName={cn('px-4', optionClassName)}
        options={regionCodeOptions}
        visibleArrow={false}
      />
    </div>
  );
};

export default SelectLanguageWidget;
