import { create } from 'zustand';
import { persist } from 'zustand/middleware';

const navigationStore = persist<any>(
  (set, get) => ({
    hasHydrated: false,
    setHasHydrated: (state) => {
      set({
        hasHydrated: state,
      });
    },
    navigationLists: {
      'show-navigation': true,
    },
    setNavigationLists: (key) => {
      return (newValue) => {
        const navigationData = get().navigationLists;
        set(() => ({
          navigationLists: {
            ...navigationData,
            [key]: newValue,
          },
        }));
      };
    },
  }),
  {
    name: 'navigation-lists',
    partialize: (state) => ({
      navigationLists: state.navigationLists,
    }),
    version: 1,
    skipHydration: true,
    onRehydrateStorage: () => (state) => {
      state.setHasHydrated(true);
    },
  },
);

export const useNavigationStore = create(navigationStore);
