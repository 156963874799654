import { cn } from 'class-merge';
import Image from 'next/image';
import type { FC, RefObject } from 'react';
import { useEffect, useRef, useState } from 'react';
import {
  BentoMenuFilled,
  CaretDownOutlined,
  ListMenuFilled,
} from '@packages/icons-react';
import Navbar from '~components/layout/navber';
import PageUrls from '~constants/page-urls';
import images from '~build-config/images.json';
import { useRouter } from '~hooks/use-router';
import { useNavigationStore } from '~store/navigation-store';
import SelectLanguageWidget from '~components/widget/select-language-widget';
import { DEFAULT_LANGUAGE, HomepageLayoutEnum } from '~constants/etc';
import { useLayoutStore } from '~store/layout-store';
import appConfig from '~build-config/config.json';

interface HeaderProps {
  locale?: string;
  isVisibleNavbar?: boolean;
  isCollapse?: boolean;
  onSizeChanged?: (value: number) => void;
}

const Header: FC<HeaderProps> = (props) => {
  const {
    locale = DEFAULT_LANGUAGE,
    isVisibleNavbar = true,
    isCollapse = false,
    onSizeChanged,
  } = props;
  const router = useRouter();
  const ref = useRef() as RefObject<HTMLDivElement>;
  const [visibleSidebar, setVisibleSidebar] = useState<boolean>(false);
  const {
    navigationLists: showNavigation,
    setNavigationLists: setShowNavigation,
  } = useNavigationStore((state) => {
    return {
      navigationLists: state.navigationLists['show-navigation'],
      setNavigationLists: state.setNavigationLists('show-navigation'),
    };
  });
  const { layoutLists: homepageLayout, setLayoutLists: setHomepageLayout } =
    useLayoutStore((state) => {
      return {
        layoutLists: state.layoutLists['homepage-layout'],
        setLayoutLists: state.setLayoutLists('homepage-layout'),
      };
    });
  const isShowNavigation = !isCollapse || showNavigation;

  useEffect(() => {
    if (ref.current && typeof onSizeChanged === 'function') {
      onSizeChanged(parseFloat(ref.current.style.height));
    }
  }, [showNavigation]);

  const handleClickNavigation = (isVisible: boolean): void => {
    if (isVisible) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
    setVisibleSidebar(isVisible);
  };

  return (
    <>
      <div
        aria-hidden
        className={cn(
          'fixed top-0 z-30 h-screen w-screen bg-black transition-all duration-700',
          visibleSidebar ? 'visible opacity-80' : 'invisible opacity-0',
        )}
        onClick={() => {
          handleClickNavigation(false);
        }}
        role="button"
      />
      <div
        className={cn(
          'bg-color-top-navbar shadow-navbar top-0 grid w-full grid-cols-2 items-center px-5 transition-all duration-700',
          isCollapse ? 'relative grid-cols-3' : 'absolute grid-cols-2',
        )}
        ref={ref}
        style={{
          height: isShowNavigation ? 70 : 20,
        }}
      >
        <Image
          alt="logo"
          className={cn(
            'max-h-[60px] cursor-pointer object-contain object-left transition-all duration-700 sm:max-h-[70px]',
            isShowNavigation
              ? 'visible translate-y-0'
              : 'invisible -translate-y-14',
          )}
          height={60}
          onClick={() => router.push(PageUrls.HOME)}
          src={images.horizontalLogo}
          width={120}
        />
        {isCollapse ? (
          <div className="absolute bottom-0 flex w-full cursor-pointer select-none justify-center outline-none">
            <div
              aria-hidden
              className="rounded-t-lg bg-[#071125] bg-opacity-50 shadow-[inset_0px_2px_4px_0.1px_rgba(0,0,0,0.6)]"
              onClick={() => {
                setShowNavigation(!showNavigation);
              }}
              role="button"
            >
              <div
                className={cn(
                  'flex w-[60px] items-center justify-center',
                  showNavigation && 'rotate-180',
                )}
              >
                <CaretDownOutlined />
              </div>
            </div>
          </div>
        ) : (
          <div
            className={cn(
              'absolute mt-1 flex items-center gap-4',
              isVisibleNavbar ? 'right-14' : 'right-5',
            )}
          >
            <SelectLanguageWidget
              className="relative"
              locale={locale || DEFAULT_LANGUAGE}
            />
            {isVisibleNavbar ? (
              <div
                className={cn(
                  'cursor-pointer text-lg',
                  appConfig.customClassName?.headerIconClassName ||
                    'text-white/30 hover:text-white',
                )}
              >
                {homepageLayout === HomepageLayoutEnum.Grid ? (
                  <ListMenuFilled
                    onClick={() =>
                      setHomepageLayout(HomepageLayoutEnum.Sidebar)
                    }
                  />
                ) : (
                  <BentoMenuFilled
                    onClick={() => setHomepageLayout(HomepageLayoutEnum.Grid)}
                  />
                )}
              </div>
            ) : null}
          </div>
        )}

        {isVisibleNavbar ? (
          <Navbar
            containerClassName={
              isShowNavigation ? 'visible' : 'translate-x-96 invisible'
            }
            iconMenuClassName={
              isShowNavigation ? 'translate-y-0' : '-translate-y-14'
            }
            isVisible={visibleSidebar}
            onClickNavigation={() => {
              handleClickNavigation(!visibleSidebar);
            }}
          />
        ) : null}
      </div>
    </>
  );
};

export default Header;
