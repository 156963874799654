import {
  BanksOutlined,
  CardOutlined,
  DollarPaperOutlined,
  FooterOutlined,
  GiftOutlined,
  HomeOutlined,
  SanctuaryOutlined,
  SheetOutlined,
  SlotOutlined,
  UserImageOutlined,
} from '@packages/icons-react';
import type { IconReactProps } from '@packages/icons-react/components/IconReact';
import type { ForwardRefExoticComponent, RefAttributes } from 'react';
import PageUrls from './page-urls';

export const Menus: {
  key: string;
  icon: ForwardRefExoticComponent<
    Omit<IconReactProps, 'ref'> & RefAttributes<HTMLSpanElement>
  >;
  text: string;
  href: string;
}[] = [
  {
    key: '/',
    icon: HomeOutlined,
    text: 'homepage-menu',
    href: PageUrls.HOME,
  },
  {
    key: '/financial/deposit',
    icon: SanctuaryOutlined,
    text: 'financial-menu',
    href: PageUrls.FINANCIAL_DEPOSIT,
  },
  {
    key: '/financial/transaction',
    icon: SheetOutlined,
    text: 'financial-transaction-menu',
    href: PageUrls.FINANCIAL_TRANSACTION,
  },
  {
    key: '/affiliate',
    icon: BanksOutlined,
    text: 'affiliate-menu',
    href: PageUrls.AFFILIATE,
  },
  {
    key: '/sport',
    icon: FooterOutlined,
    text: 'sport-menu',
    href: PageUrls.SPORT,
  },
  {
    key: '/casino',
    icon: CardOutlined,
    text: 'casino-menu',
    href: PageUrls.CASINO,
  },
  {
    key: '/gaming',
    icon: SlotOutlined,
    text: 'gaming-menu',
    href: PageUrls.GAMING,
  },
  {
    key: '/bet-transactions',
    icon: DollarPaperOutlined,
    text: 'bet-transaction-menu',
    href: PageUrls.BET_TRANSACTIONS_CASINO,
  },
  {
    key: '/reward',
    icon: GiftOutlined,
    text: 'mission-menu',
    href: PageUrls.MISSIONS,
  },
  {
    key: '/profile',
    icon: UserImageOutlined,
    text: 'account-menu',
    href: PageUrls.PROFILE,
  },
];
