import {
  HamburgerLeftOutlined,
  HamburgerRightOutlined,
} from '@packages/icons-react';
import Link from 'next/link';
import { cn } from 'class-merge/src';
import { usePathname } from 'next/navigation';
import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Menus } from '~constants/menu';
import appConfig from '~build-config/config.json';

interface NavbarProps {
  isVisible: boolean;
  iconMenuClassName?: string;
  containerClassName?: string;
  onClickNavigation: () => void;
}

const Navbar: FC<NavbarProps> = (props) => {
  const {
    isVisible,
    iconMenuClassName,
    containerClassName,
    onClickNavigation,
  } = props;
  const { t, i18n } = useTranslation('menus');
  const pathname = usePathname();
  const [activeMenu, setActiveMenu] = useState<string>('/');

  useEffect(() => {
    const regex = new RegExp(`^/${i18n.language}`);
    const path = pathname.replace(regex, '');
    setActiveMenu(path || '/');
  }, [pathname, i18n.language]);

  return (
    <nav
      className={cn(
        'absolute right-0 top-0 h-screen w-[280px] transition-all duration-700',
        isVisible ? 'bg-color-menu visible z-40' : 'translate-x-72',
        containerClassName,
      )}
    >
      <div
        aria-hidden="true"
        className={cn(
          'text-hamburger relative flex h-[70px] cursor-pointer items-center gap-6 p-4 text-xl outline-none sm:text-2xl',
          appConfig.customClassName?.headerIconClassName ||
            'text-white/30 hover:text-white',
          isVisible ? 'left-0 shadow-xl' : '-left-16',
          iconMenuClassName,
        )}
        onClick={onClickNavigation}
        role="button"
        tabIndex={-1}
      >
        {isVisible ? (
          <HamburgerRightOutlined className="pt-1 text-xl" />
        ) : (
          <HamburgerLeftOutlined className="pt-1 text-xl" />
        )}
        <p
          className={cn(
            'text-xl transition-opacity duration-700',
            isVisible ? 'opacity-100' : 'opacity-0',
          )}
        >
          {t('menu-text')}
        </p>
      </div>
      <div
        className={cn(
          'mt-2 h-screen w-[280px] flex-col overflow-hidden overflow-y-auto pb-20',
          isVisible ? 'visible opacity-100' : 'invisible',
        )}
      >
        {Menus.map((menu) => {
          const textActiveColor =
            appConfig.customClassName?.textActiveMenu || 'text-color-primary';
          return (
            <Link
              className={cn(
                'text-color-secondary hover:bg-color-menu-active flex cursor-pointer items-center gap-6 p-4 text-sm transition-all duration-700 sm:text-base',
                `hover:${textActiveColor}`,
                activeMenu === menu.key &&
                  `bg-color-menu-active ${textActiveColor}`,
              )}
              href={menu.href}
              key={menu.key}
              onClick={onClickNavigation}
            >
              <menu.icon className="text-xl sm:text-2xl" />
              {t(`menus:${menu.text}` as any)}
            </Link>
          );
        })}
      </div>
    </nav>
  );
};

export default Navbar;
